@import "style/_variables.scss";

.bodyContent {
  width: 100%;
  max-width: 90ch;
  padding: 0 20px 60px;
  p, h1, h2, h3, h4, h5, h6{
    margin-block-start: 1rem;
    margin-block-end: 1rem;
  }
  blockquote {
    margin-inline-start: 1rem;
    margin-inline-end: 1rem;
  }
}
