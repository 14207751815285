@import "style/_mixins.scss";

.fullWidthImage {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 0 40px;
}

.fullWidthImage img {
  max-width: 100vw;
}
