@import "style/_mixins.scss";

.container {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 30px 0 40px;
}

.image {
  flex: 1;
  max-width: 100%;
  object-fit: contain;
  min-width: 0;
}

@media screen and (max-width: $bp-md) {
  .container {
    flex-direction: column;
  }
}
