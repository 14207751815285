@import "style/_mixins.scss";

.introPictureBack {
  background-color: var(--text-color);
  z-index: 3;
  position: relative;
  transform-style: preserve-3d;
}

.tilt {
  transform-style: preserve-3d;
}

.introShadow {
  height: calc(100% - 80px);
  width: calc(100% - 80px);
  background-color: rgba(0, 0, 0, 0.5);
  transform: translateZ(-100px);
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: content-box;
  margin: 40px;
  filter: blur(40px);
}

.introPicture {
  max-height: 600px;
  max-width: 100vw;
  transform: translateZ(100px);
  padding: 80px;
}

.introPictureContainer {
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  border-bottom: 2px solid var(--text-color);
  position: relative;
  z-index: 5;
  padding-top: 80px;
  max-height: 680px;
}

.hidden {
  display: none;
}

@media screen and (max-width: 780px) {
  .introPicture {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: $bp-lg) {
  .introPictureContainer {
    height: unset;
  }
}

@media screen and (max-width: $bp-md) {
  .introPicture {
    padding: 80px 40px;
  }
}