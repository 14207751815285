@import "style/_variables.scss";
@import "style/_mixins.scss";

.projectCard {
  color: var(--text-color);
  overflow: hidden;
  text-decoration: none;
  border-radius: 0.5em;
  border: 2px solid var(--text-color);
  font-weight: 500;
  margin: 0.25em;
  background: var(--bg-color);
  display: flex;
  flex-direction: column;
  max-width: 300px;
  flex: 1;
  cursor: pointer;
  @include smoothness(0.4s);

  &:hover {
    @include smoothness(0.4s);
    transform: scale(1.02);
    box-shadow:
      4px 4px 10px rgba(var(--text-color-rgb), 0.25) !important;
  }

  &:active {
    transform: scale(1);
    box-shadow: 2px 2px 4px rgba(var(--text-color-rgb), 0.5) !important;
  }
}

@media (hover: hover) {

  .projectCard:hover .subtitle {
    color: inherit;
  }
}

.tile {
  width: 100%;
  height: auto;
  border-bottom: 2px solid var(--text-color);
  aspect-ratio: 1 / 1;
}

.titleContainer {
  padding: 20px;
  min-width: 100px;
}

.title {
  font-size: 1.25rem;
  color: var(--text-color);
}

.subtitle {
  font-size: 1rem;
  color: var(--text-color);
}

.subtitle span {
  color: var(--text-color);
}

@media screen and (max-width: $bp-lg) and (min-width: $bp-sm) {
  .projectCard {
    flex-direction: row;
    max-width: unset;
    max-height: 200px;
    width: 100%;
  }

  .tile {
    width: 200px;
    border-bottom: unset;
    border-right: 2px solid var(--text-color);
    height: 100%;
  }
}

@media screen and (max-width: $bp-sm) {
  .projectCard {
    max-width: unset;
    width: 100%;
  }

  .tile {
    object-fit: cover;
    max-height: 20vh;
  }
}