@import "style/_variables.scss";
@import "style/_mixins.scss";

.feed {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-background {
  padding: 20px;
  background-color: red;
}

.intro-container {
  position: absolute;
  padding: 20px;
  border: solid 2px var(--text-color);
  width: 100%;
}

/*TYPOGRAPHY*/
.introText {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 6vw;
  line-height: 8vw;
  font-family: "SUSE", sans-serif;
  font-weight: bold;
  font-style: italic;
  text-align: left;
  text-decoration: none;
  &:hover {
    color: red;
  }
}

.scrollContainer {
  display: flex;
  flex-direction: column;
  height: 200px;
  overflow: scroll;
  h2 {
    font-size: 6vw;
    font-weight: bold;
  }
}

@media only screen and (max-width: 972px) {
}

@media only screen and (max-width: 620px) {
}

.intro-text a {
  color: transparent;
  -webkit-text-stroke-width: 0.113vw;
  -webkit-text-stroke-color: var(--text-color);
  text-decoration: none;
}
.intro-text a:hover {
  background: -webkit-linear-gradient(180deg, #ff00ff, #00ffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 0px;
}

h1 {
  font-size: 3rem;
  font-family: "SUSE", sans-serif;
  font-weight: regular;
}

h3.label {
  color: var(--text-color);
  font-size: 1rem;
  font-family: "SUSE", sans-serif;
  font-weight: bolder;
}
