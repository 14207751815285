@import "style/_variables.scss";
@import "style/_mixins.scss";

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  padding: 40px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  @include smoothness(0.4s);
  .headerLink {
    color: var(--text-color);
    padding: 16px;
    font-size: 0.825em;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.125em;
    opacity: 0.75;
    @include smoothness(0.2s);
    &:hover {
      -webkit-transform: scale(0.94);
      transform: scale(0.94);
      opacity: 1;
    }
    &:active {
      color: $pop;
    }
    span {
      color: $pop;
      padding-right: 4px;
    }
  }
  &.hidden {
    margin-top: -200px;
  }
  .logo {
    grid-row: 2;
    height: 30px;
  }
}

.headerSpacer {
  width: 140px;
}

.headerButton {
  color: var(--bg-color);
  background: $pop;
  border-radius: 8px;
  padding: 0.625em 2em;
  font-size: 0.825em;
  font-weight: 500;
  text-transform: uppercase;
  &:hover {
    color: var(--bg-color);
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  &:active {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
}

.homeLink {
  background-color: unset;
  border: unset;
  cursor: pointer;
  z-index: 999;
  fill: var(--text-color);
}

.productTitle {
  @include glow;
  top: 12px;
  color: var(--bg-color);
  text-transform: uppercase;
  margin-left: 0.35em;
  span {
    font-size: 0.875em;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: var(--bg-color);
  }
}

.desktopLinks {
  justify-self: flex-end;
  display: inline-block;
  margin-right: 40px;
  @include smoothness(0.2s);
  .header-button {
    margin-left: 1em;
    display: inline-block;
  }
}

.absolute {
  position: absolute;
}

.hamburger {
  height: 60px;
  justify-self: flex-end;
  cursor: pointer;
  display: none;
  path {
    fill: none;
    -webkit-transition:
      stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -moz-transition:
      stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -o-transition:
      stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -ms-transition:
      stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    transition:
      stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    stroke-width: 20px;
    stroke-linecap: round;
    stroke: var(--text-color);
    stroke-dashoffset: 0px;
  }
  path#top,
  path#bottom {
    stroke-dasharray: 240px 950px;
  }
  path#middle {
    stroke-dasharray: 240px 240px;
  }

  &.cross path#top,
  &.cross path#bottom {
    stroke-dashoffset: -650px;
    stroke-dashoffset: -650px;
  }
  &.cross path#middle {
    stroke-dashoffset: -115px;
    stroke-dasharray: 1px 220px;
  }
  &:hover {
    path {
      stroke: currentColor;
    }
  }
}

.headerScrim {
  position: fixed;
  left: 0;
  width: 100%;
  height: 180px;
  z-index: 2;
  transition: background 0.4s cubic-bezier(0.25, -0.25, 0.75, 1.25);
  background: linear-gradient(to bottom, var(--bg-color), transparent);
}

@media only screen and (max-width: 690px) {
  .desktopLinks {
    display: flex;
    background: color-mix(in srgb, var(--bg-color) 75%, transparent);
    height: auto;
    width: 100%;
    align-items: center;
    flex-direction: column;
    left: -100vw;
    top: 0;
    position: fixed;
    padding-top: 90px;
    padding-bottom: 2em;
    z-index: -1;
    margin-right: unset;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
    @include smoothness(0.5s);
    .headerLink {
      padding: 20px;
      
    }
  }

  .desktopLinks.isActive {
    left: 0;
  }

  .hamburger {
    display: inline-block;
  }
}

@media only screen and (max-width: 440px) {
}
