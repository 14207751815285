@import "~style/_mixins.scss";

.introContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.intro {
  // background: radial-gradient(ellipse 100% 100% at 100% 100%, rgb(32, 0, 73) 0%, rgb(0, 0, 0) 100%);
  display: grid;
  grid-template-columns: 1fr 200px;
  grid-template-rows: repeat(3, auto);
  gap: 20px;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: calc(90ch + 400px);
  padding: 0 20px 20px 220px;
}

.projectGradientSide {
  display: block;
  height: auto;
  margin-top: 7px;
  margin-bottom: 7px;
  width: 10px;
  margin-right: 2%;
  margin-left: -2.7%;
}

.projectGradientTop {
  height: 10px;
  width: 100%;
  display: none;
}

.clientName {
  margin-bottom: 0.4em;
  text-decoration: none;
  margin-top: 40px;
  display: block;
  h2 {
    padding: 0;
  }
}

.clientBody {
  a {
    font-weight: 500;
    @include smoothness (.2s);
    &:hover {
      font-weight: 900;
    }
    &:active {
      font-weight: 300;
    }
  }
}
.visit {
  margin-top: 40px;
  text-decoration: none;
  display: flex;
  a:hover {
    transition: 0.5s;
  }
}

.eyebrow {
  font-size: 1.2rem;
  span, a {color: var(--text-color)};
  a { @include smoothness(.2s)};
  a:hover {
    color: inherit;
  }
}

.title {
  display: flex;
  line-height: 1.8em;
}

.clientName, .visit {
  align-self: flex-end;
}

.hats {
  display: flex;
  margin-top: 0px;
  grid-row: 3;
  grid-column: span 2;
  gap: 6px;
}
.hat {
  font-size: 14px;
  border-radius: 20px;
  padding: 2px 10px;
  color: white;
  white-space: nowrap;
  @include smoothness(.2s);
  &:hover {
    font-weight: 700;
  }

}

@media only screen and (max-width: $bp-xl) {
  .intro {
    max-width: 90ch;
    padding: 0 20px 20px;
  }
  .visit {
    margin-top: 40px;
  }
}

@media only screen and (max-width: $bp-lg) {
  .projectGradientTop {
    display: unset;
  }
  .projectGradientSide {
    display: none;
  }
}

@media only screen and (max-width: $bp-md) {
  .intro {
  }
  .clientName, .clientBody {
    grid-column: span 2;
  }

  .hats {
    grid-column: span 2;
    grid-row: 3;
    flex-wrap: wrap;
  }

  .hat {
    width: min-content;
  }

  .visit {
    grid-row: 4;
    margin-top: 0;
    align-self: unset;
  }
}

@media only screen and (max-width: $bp-sm) {
  .intro {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, auto);
    max-width: 90ch;
    padding: 0 20px 20px;
  }
  .clientName, .clientBody {
    grid-column: span 2;
    grid-row: unset;
  }

  .hats {
    flex-direction: column;
    grid-column: 1;
    grid-row: 3;
  }

  .visit {
    grid-column: 2;
    grid-row: 3;
  }
}
