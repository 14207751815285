@import "style/_variables.scss";
@import "style/_mixins.scss";

.footer {
  border-top: 2px solid var(--text-color);
  padding: 40px 20px 60px;
  margin: 0 auto;
}

.content {
  max-width: 824px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
}

.projectsContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.catchphrase {
  margin-bottom: 1em;
  max-width: 824px;
  width: 100%;
}

.catchphrase2 {
  margin-top: 1em;
  max-width: 824px;
  align-self: flex-end;
}

.edge {
  border-top: 4px solid var(--text-color);
  border-style: outset;
  background-color: var(--bg-color);
  height: 14px;
  width: 100%;
}

a {
  font-weight: 500;
  @include smoothness(0.2s);
  &:hover {
    font-weight: 700;
  }
}

@media screen and (max-width: $bp-lg) and (min-width: $bp-sm) {
  .projectsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
    gap: 1rem;
  }
}

@media only screen and (max-width: $bp-sm) {
  .projectsContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
