@import "style/_variables.scss";
@import "style/_mixins.scss";

.homeHero {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.jf {
  width: 20px;
}

.logo {
  grid-row: 2;
  height: 30px;
  @include smoothness(0.2s);
}

.message {
  cursor: crosshair;
}

.page-background {
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding: 10px;
}

.page-container {
  border: solid 2px black;
  background: var(--bg-color);
  width: 100%;
  height: calc(100vh - 20px);
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.selectedProjects {
  display: flex;
  flex-direction: row;
  padding: 40px 20px;
  gap: 20px;
}

@media screen and (max-width: $bp-lg) {
  .selectedProjects {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .homeHero {
    height: auto;
    align-items: unset;
  }
}

@media screen and (max-width: $bp-sm) {
  .selectedProjects {
    padding: 40px 0;
  }
}
