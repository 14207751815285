@import "style/_variables.scss";
@import "style/_mixins.scss";

.headshot {
  border-radius: 100%;
  max-width: 300px;
  border: 4px solid var(--text-color);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 2rem;
}

.aboutContent {
  a {
    font-weight: 500;
    @include smoothness (.2s);
    &:hover {
      font-weight: 900;
    }
    &:active {
      font-weight: 300;
    }
  }
}