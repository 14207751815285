@import "style/_variables.scss";
@import "style/_mixins.scss";

.projectContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;
  h1 {
    max-width: 800px;
    width: 100%;
  }
  h2 {
    padding-left: 1rem;
  }
  p {
    max-width: 800px;
  }
  a {
    font-weight: 500;
    @include smoothness (.2s);
    &:hover {
      font-weight: 900;
    }
    &:active {
      font-weight: 300;
    }
  }
}
