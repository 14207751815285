/**
 *
 * Modal Manager 
 *
**/

.modal-container {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
