@import "style/_variables.scss";
@import "style/_mixins.scss";

.homeHero {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.helloButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1em;
}

.homeHero h2 {
  padding: 20px;
  font-weight: 100;
}

.greeting {
  cursor: default;
}

.greeting-2 {
  margin-bottom: 20px;
}

h3.label {
  color: var(--text-color);
  font-size: 1rem;
  font-family: "SUSE", sans-serif;
  font-weight: bolder;
}

@media only screen and (max-width: $bp-sm) {
  .hello-buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
