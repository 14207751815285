/*
 * App Style
 *
 * The base style for the application.
 *
 */
@charset "utf-8/index";

@import "_base.scss";
@import "_variables.scss";
@import "_mixins.scss";
@import "_typography.scss";

@keyframes fontfix {
  from {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

::selection {
  background: var(--text-color);
  color: var(--bg-color);
}

.jf {
  position: absolute;
  height: 56px;
  margin-top: 40px;
  fill: var(--text-color);
}

.jf:hover {
  -webkit-animation: party 2s;
  -moz-animation: party 2s;
  animation: party 2s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  mix-blend-mode: normal;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

body {
  margin: 0;
}

code {
  font-family: "Fira Code", monospace;
}

.button {
  color: var(--text-color);
  text-decoration: none;
  padding: .5em 1em;
  border-radius: 0.5em;
  border: 2px solid var(--text-color);
  font-weight: 500;
  margin: 0.25em;
  background: var(--bg-color);
  display: inline;
  @include smoothness(0.4s);

  &.tall {
    padding: 1em;
  }

  &:hover {
    @include smoothness(0.4s);
    transform: scale(1.02);
    box-shadow: 4px 4px 8px rgba(var(--text-color-rgb), 0.25) !important;

  }

  &:active {
    transform: scale(1);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5) !important;
  }
}

.copyright {
  color: #d1d1d1;
  font-size: 14px;
  position: absolute;
  bottom: 20px;
  right: 30px;
  @include smoothness(0.4s);

  &:hover {
    color: #454545;
  }
}

.pageOffset {
  padding: 20px;
  margin-top: 80px;
}