@import "style/_variables.scss";
@import "style/_mixins.scss";

.pageBackground {
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding: 10px;
  background: var(--bg-color);
  @supports (height: 100dvh) {
    height: 100dvh;
  }
}

.pageBackground.hidden {
  padding: 0px;
  .pageContainer {
    height: 100vh;
    border: solid 0 var(--text-color);
    @supports (height: 100dvh) {
      height: 100dvh;
    }
  }
}

.pageContainer {
  display: flex;
  flex-direction: column;
  border: solid 4px var(--text-color);
  background: var(--bg-color);
  width: 100%;
  height: calc(100dvh - 20px);
  overflow: scroll;
  z-index: 4;
  position: relative;
  transition: background 0.4s cubic-bezier(0.25, -0.25, 0.75, 1.25),
    border 0.4s cubic-bezier(0.25, -0.25, 0.75, 1.25);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}
