/*
 * Typography
 *
 * Website-wide text styling
 *
 */

@import "variables.scss";
@import "mixins.scss";

//PROJECT FONTS
@import url("https://fonts.googleapis.com/css2?family=SUSE:wght@100..800&display=swap");

* {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  vertical-align: inherit;
  line-height: 1.2em;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "SUSE", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

// body  {
//   font-family: "Fira Code", monospace;
// }

a {
  color: inherit;
  text-decoration: none;
}

strong {
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0px;
}

p,
li,
a,
blockquote,
h4 {
  font-size: 1.2rem;
}

h2 {
  font-size: 2rem;
  font-family: "SUSE", sans-serif;
}

h3 {
  font-size: 1.4rem;
}

p {
  margin: 0px;
}

p + p {
  margin-top: 0.75em;
}

li + li {
  margin-top: 0.5em;
}

strong {
  font-weight: 500;
  @include smoothness(0.2s); 
  &:hover {
    font-weight: 700;
  }
}
