@import "style/_variables.scss";
@import "style/_mixins.scss";

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  gap: 1rem;
}

.introContainer {
  position: absolute;
  padding: 20px;
  border: solid 2px var(--text-color);
  width: 100%;
}

/*TYPOGRAPHY*/
.introText {
  cursor: pointer;
  display: block;
  font-size: 6vw;
  line-height: 8vw;
  font-family: "SUSE", sans-serif;
  font-weight: bold;
  font-style: italic;
  text-align: left;
  text-decoration: none;
  &:hover {
    color: red;
  }
}

.jf {
  width: 20px;
}

@media screen and (max-width: $bp-lg) and (min-width: $bp-sm) {
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
    gap: 1rem;
  }
}

@media only screen and (max-width: 620px) {
}

.introText a {
  color: transparent;
  -webkit-text-stroke-width: 0.113vw;
  -webkit-text-stroke-color: #3d3d3d;
  text-decoration: none;
}
.introText a:hover {
  background: -webkit-linear-gradient(180deg, #ff00ff, #00ffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 0px;
}

h1 {
  font-size: 3rem;
  font-family: "SUSE", sans-serif;
  font-weight: regular;
}

h3.label {
  color: var(--text-color);
  font-size: 1rem;
  font-family: "SUSE", sans-serif;
  font-weight: bolder;
}
