@import "style/_variables.scss";
@import "style/_mixins.scss";

.full-page-loader {
  max-height: 100dvh;
  width: 100vw;
  // iOS hotfix
  min-height: 100dvh;
  min-height: -webkit-fill-available;
  position: absolute;
  background-color: var(--bg-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .border-animation {
    box-sizing: border-box;
    position: absolute;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    stroke-width: 8;
  }
  .logo-animation {
    position: absolute;
    width: 30px;
    .jf-border {
      fill: none;
      stroke-width: 8;
      stroke-miterlimit: 10;
    }
    .jf-letter {
      fill: none;
      stroke-width: 10;
      stroke-miterlimit: 10;
    }
    .st2 {
      fill: var(--bg-color);
    }
  }
}

@supports (height: 100dvh) {
  .full-viewport-height {
    height: 100dvh;
  }
}